import { BreadcrumbsModel } from '@shared/breadcrumbs/breadcrumb.model';
import { Data } from '@angular/router';
import { PermissionOptions } from '@shared/model/permission-options.model';

interface BreadcrumbData {
  breadcrumb: {
    label: string;
    permissions?: PermissionOptions | null;
    customParentBreadcrumb?: BreadcrumbsModel;
  };
}

type BreadcrumbContext = {
  breadcrumbContext: 'cross';
};

export const BREADCRUMB_CROSS_CONTEXT: BreadcrumbContext = {
  breadcrumbContext: 'cross',
};

export function getBreadcrumbContext(
  state: { [k: string]: unknown } | undefined
): BreadcrumbContext['breadcrumbContext'] | undefined {
  return (state as unknown as BreadcrumbContext)?.breadcrumbContext;
}

// This add a new property to route data with main key of breadcrumb that has a label
// and customParentBreadcrumb
export function withBreadcrumbData({
  label,
  permissions,
  customParentBreadcrumb,
}: BreadcrumbData['breadcrumb']) {
  return {
    breadcrumb: {
      label,
      permissions,
      customParentBreadcrumb,
    },
  };
}

export function getBreadcrumbData(data?: Data) {
  return (data as BreadcrumbData)?.breadcrumb;
}
